<template>
  <div id="faq-view">
    <content-section
      type="sub-section"
      :title="$localize.get('faq')"
    />
    
    <content-section
      type="tab-section"
      :tabs="faqSectionTabs"
      blue-bg
    >
      <!-- <template v-slot:content-0>
        <v-expansion-panels v-model="expansionPanels.customers">
          <v-expansion-panel
            v-for="(item, index) in faqItemsForCustomers[selectedLanguage.locale]"
            :key="index"
          >
            <v-expansion-panel-header>
              {{item.title}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{item.description}}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template> -->
      <template v-slot:content-0>
        <v-expansion-panels v-model="expansionPanels.contractors">
          <v-expansion-panel
            v-for="(item, index) in faqItemsForContractors[selectedLanguage.locale]"
            :key="index"
          >
            <v-expansion-panel-header>
              {{item.title}}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              {{item.description}}
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </content-section>
  </div>
</template>

<script>
import {mapFields} from 'vuex-map-fields'

export default {
  name: 'FAQ',
  data () {
    return {
      expansionPanels: {
        customers: 0,
        contractors: 0,
      }
    }
  },
  computed: {
    ...mapFields({
      faqItemsForCustomers: 'faqItemsForCustomers',
      faqItemsForContractors: 'faqItemsForContractors'
    }),
    selectedLanguage: {
      get () {
        return this.$store.state.storageData.settings.localization.selectedLanguage
      },
      set (value) {
        this.$store.state.storageData.settings.localization.selectedLanguage = value
      }
    },
    faqSectionTabs () {
      return {
        headers: [
          // this.$localize.get('for_customers'),
          this.$localize.get('for_contractors')
        ],
      }
    }
  },
}
</script>

<style lang="scss">
#faq-view {
  .v-expansion-panels > * {
    margin: 16px 0;
  }
  .v-expansion-panels .v-expansion-panel:not(:first-child)::after {
    border-color: transparent;
  }
  .v-expansion-panels .v-expansion-panel {
    background: rgba(255, 255, 255, 0.2) !important;
    border: 1px solid #DBDBDB;
    border-radius: 8px;
    &:hover {
      background: #FFFFFF !important;
      box-shadow: -8px 16px 24px rgba(0, 0, 0, 0.05);
      border-color: transparent;
    }
    .v-expansion-panel-header {
      color: var(--grey-1);
    }
  }
  .v-expansion-panels .v-expansion-panel--active {
    background: #FFFFFF !important;
    border-color: transparent;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.08);
    .v-expansion-panel-header {
      padding-bottom: 0;
      color: var(--blue-main);
    }
  }
  .v-expansion-panels .v-expansion-panel::before {
    box-shadow: none !important;
  }
  .v-expansion-panel-header {
    padding: 40px 44px;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    &--active {
      .v-expansion-panel-header__icon .v-icon {
        color: var(--blue-main) !important;
      }
    }
  }
  .v-expansion-panel-content {
    color: var(--grey-2);
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
  }
  .v-expansion-panel-content__wrap {
    padding: 40px 44px;
    padding-top: 24px;
  }
}
</style>